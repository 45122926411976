div.game {
	margin: 2rem auto 3rem auto;
	align-items: center;

	>table {
		font-size: 2.5rem;
		line-height: 2.5rem;
		text-align: center;

		tr>td {
			width: 4rem;
			height: 4rem;
			border: 2px solid var(--text);

			div {
				width: 90%;
				height: 90%;
				margin: 5%;
				border-radius: .5rem;
				transition: var(--transition5);

				&:empty:hover {
					cursor: pointer;
					background: var(--body);
					opacity: 50%;
				}

				>img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	>div.result {
		width: 16rem;
		margin: 1rem auto 0rem auto;

		>div.title {
			padding: .5rem;
			border-radius: .5rem;
			text-align: center;
			font-size: 1.5rem;
			color: var(--mint);
			background: var(--body);
		}

		>div.buttons {
			margin-top: .75rem;
			height: 2.5rem;

			>div {
				flex: 1;
				font-size: 1.1rem;
				text-align: center;
				color: var(--mariana0);
			}

			>div.minus {
				flex: 1;
				border-radius: .5rem 0rem 0rem .5rem;
			}

			>div.restart {
				flex: 4;
				border-radius: 0rem;
			}

			>div.plus {
				flex: 1;
				border-radius: 0rem .5rem .5rem 0rem;
			}
		}
	}

	p {
		text-align: center;
	}
}
