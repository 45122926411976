section.LoadingPage {
	margin: auto;

	>div {
		display: flex;
		align-items: center;
		text-align: center;

		>div {
			width: 7.5rem !important;
		}
	}
}
