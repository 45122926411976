svg.simon {
	max-width: 16rem;

	>g {
		>* {
			cursor: no-drop;
			transition: var(--transition);
		}

		&:hover {

			>.arms,
			>.chest {
				transform: translateY(-2rem);
			}
		}

		>.eyes {
			animation: blink 5s ease infinite;

			@keyframes blink {
				90% {
					opacity: 1;
				}

				95% {
					opacity: 0;
				}

				100% {
					opacity: 1;
				}
			}
		}
	}
}
