/**************************************************************
 *****                 FOOTER STARTS HERE                 *****
 **************************************************************/
footer {
	margin: auto 1rem 1rem 1rem;
	border-radius: .5rem;
	background: var(--body);
	align-items: center;

	>div.deployment {
		margin: .25rem 0rem;

		>a.image.link {
			margin: .25rem .5rem;
		}
	}

	>div.copyright {
		margin: .5rem 0rem;
		font: .8rem monospace;
	}
}
