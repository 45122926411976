a.sponsor.button {

	>div.dimensional.button {

		>div {

			>span.sponsor {
				margin: 0rem .5rem;
			}
		}
	}
}
