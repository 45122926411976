div.button.floating {
	padding: .5rem;
	border-radius: .5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--mariana0);
	background: var(--orange);
	transition: var(--transition);
	box-shadow: var(--shadow2);

	&:hover {
		transform: scale(1.1);
		box-shadow: var(--shadow3);
	}

	&:active {
		transform: translateY(2px);
		box-shadow: var(--shadow1);
	}
}
