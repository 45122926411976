/**************************************************************
 *****                 HEADER STARTS HERE                 *****
 **************************************************************/
header {
	width: 100%;
	padding: 1rem 1rem 0rem 1rem;
	border-radius: 1rem 1rem 0rem 0rem;
	background: var(--mint3);
	align-items: flex-end;
	justify-content: center !important;
	transition: var(--transition5);

	&.neon {
		background: var(--body);

		>h1 {
			color: white;
			font-weight: 100;
			filter: drop-shadow(var(--neon-yellow));
		}
	}

	>h1 {
		padding: 0rem 0rem 1rem 0rem;
		font: normal 3rem monospace;
		text-align: center;
		border: none;
		color: var(--mariana0);
		transition: var(--transition);
		cursor: pointer;
	}
}
