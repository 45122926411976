div.toolbar.fixed {
	position: fixed;
	right: 25px;
	bottom: 25px;
	justify-content: flex-end;
	align-items: center;

	>* {
		width: 50px;
		height: 50px;
		border-radius: 50% !important;
		margin: 8px;
		font-size: 24px;
	}
}
