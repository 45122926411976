/**************************************************************
 *****                SECTION STARTS HERE                 *****
 **************************************************************/
main {
	>a.auth.button {
		position: absolute;
		top: 2.5rem;
		right: 1rem;
	}

	>section {
		margin: 1rem 0rem;

		* {
			max-width: 100vw;
		}
	}
}
