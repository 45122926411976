div.covered.button {
	width: 12rem;
	height: 8rem;
	position: relative;
	perspective: 999px;

	>div.base {
		width: 100%;
		height: 100%;
		border-radius: 1rem;
		display: flex;
		justify-content: center;
		align-items: center;
		background: var(--body);
		box-shadow: inset var(--shadow0);
	}

	>div.cover {
		position: absolute;
		top: 0rem;
		left: 0rem;
		width: 100%;
		height: 100%;
		border-radius: 1rem;
		font-size: 2rem;
		color: var(--mariana0);
		background: var(--red0);
		opacity: .9;
		transition: var(--transition5);
		user-select: none;
		cursor: default;

		>div {
			width: 100%;
			height: 85%;
			border-radius: inherit;
			background: var(--red3);
			display: flex;

			>div {
				margin: auto 0rem 0rem 0rem;
				width: 100%;
				height: 80%;
				border-radius: inherit;
				text-align: center;
			}
		}
	}

	&.open {
		>div.cover {
			transform: translateY(-100%) rotateX(.5turn);
			transform-style: preserve-3d;
			background: var(--red3);

			>div {
				border: 2px solid hsla(357, 80%, 70%, 100%);
				background: var(--red1);

				>div {
					padding-top: 1rem;
					background: var(--red0)
				}
			}
		}
	}

	&.unlocked:hover {
		>div.cover {
			transform: translateY(-100%) rotateX(.5turn);
			transform-style: preserve-3d;
			background: var(--red3);

			>div {
				border: 2px solid hsla(357, 80%, 70%, 100%);
				background: var(--red1);

				>div {
					padding-top: 1rem;
					background: var(--red0)
				}
			}
		}
	}
}
