div.ellipsis.loader {
	display: inline-block;
	position: relative;
	width: 100%;
	height: 5rem;
	line-height: 1rem;

	>div {
		position: absolute;
		top: 2rem;
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
	}

	>div:empty {
		width: .5rem;
		height: .5rem;
		border-radius: 50%;
		background: var(--mint);
	}

	>div:nth-child(1) {
		left: calc(50% - 2.25rem);
		animation: ellipsis1 500ms infinite;
	}

	>div:nth-child(2) {
		left: calc(50% - 2.25rem);
		animation: ellipsis2 500ms infinite;
	}

	>div:nth-child(3) {
		left: calc(50% - .75rem);
		animation: ellipsis2 500ms infinite;
	}

	>div:nth-child(4) {
		left: calc(50% + .75rem);
		animation: ellipsis3 500ms infinite;
	}

	@keyframes ellipsis1 {
		0% {
			transform: scale(0);
		}

		100% {
			transform: scale(1);
		}
	}

	@keyframes ellipsis2 {
		0% {
			transform: translate(0, 0);
		}

		100% {
			transform: translate(1.5rem, 0);
		}
	}

	@keyframes ellipsis3 {
		0% {
			transform: scale(1);
		}

		100% {
			transform: scale(0);
		}
	}
}
