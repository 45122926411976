section.NeonPage {
	>div.neon {
		// position: fixed;
		// top: 50vh;
		// left: 50vw;
		// transform: translate(-50%, -50%);
		margin: 5rem auto 2.5rem auto;
		padding: 5rem;
		width: max-content;
		height: max-content;
		border-radius: 1rem;
		border: .2rem solid var(--mariana2);
		transition: var(--transition5);

		&:hover {
			border: .2rem solid white;
			box-shadow:
				0 0 1rem yellow,
				inset 0 0 1rem yellow;

			>p {
				text-shadow: 0 0 1rem yellow;
				color: white;
			}
		}

		>p {
			margin: .2rem 0;
			font: 1.2rem 'Cantata One';
			font-weight: normal;
			text-align: center;
			transition: text-shadow .3s, color .3s;
			color: #4f5b69;

			&.large {
				font: 3rem sans-serif;
			}

			>span {
				font-size: .8rem;
			}
		}
	}

	>div.BagOfCans {
		margin: 0rem auto;

		>svg {
			display: inline;
			width: 25rem;
			transition: var(--transition5);


			* {
				transition: var(--transition5);
			}

			&:hover {
				filter: drop-shadow(0 0 .5rem yellow);

				polygon.outline {
					stroke: white;
					stroke-width: 16;
				}

				line {
					stroke: white;
					stroke-width: 7.5;
				}

				circle {
					fill: white;
				}
			}

			&.two:hover {

				line,
				circle {
					transform: translate(0, 90px);
				}

				text {
					display: none;
				}
			}

			&.three:hover {

				line,
				circle {
					transform: translate(0, 90px);
				}

				polygon.outline {
					transform: rotate(30deg);
				}

				text {
					display: none;
				}
			}
		}
	}
}
