section.ButtonsPage {
	margin: auto;

	div.button.dimensional {
		margin-top: 0rem;
		translate: 0%;
	}

	>div {
		align-items: center;

		>div {
			align-items: center;

			>div.button {
				margin: 1.5rem;
			}
		}
	}
}
