main {
	min-height: calc(100vh - 2rem);
	width: max-content;
	min-width: 50vw;
	max-width: 100vw;
	margin: 1rem auto;
	border-radius: 1rem;
	background: var(--main);

	* {
		max-width: 100vw;
	}

	h1 {
		margin: 1rem 0rem;
		font: normal 2rem monospace;
		text-align: center;
	}

	h2 {
		margin: 2rem 1.5rem 0rem 1.5rem;
		padding: 0rem 1rem;
		font-weight: normal;
		border-bottom: var(--border-title);
		color: var(--orange);
	}

	hr {
		margin: 1rem 1.5rem;
		border: none;
		border-bottom: var(--border-title);
	}
}

body.mariana.dark {
	--body: var(--mariana0);
	--main: var(--mariana1);
	--text: var(--mariana3);
	--card: var(--mariana2);
	--mint: var(--mint3);
	--orange: var(--orange3);
}

body.mariana.light {
	--body: var(--mariana3);
	--main: white;
	--text: var(--mariana0);
	--card: var(--mariana3);
	--mint: var(--mint1);
	--orange: var(--orange2);
}
