section.Home {

	>h2 {
		margin-top: 4.5rem;
	}

	>div.hello {
		justify-content: center;

		>pre {
			flex: 1;
			margin: 1rem 2rem;
		}

		>div.right {
			flex: 1;
			margin: 1rem 2rem;
			min-width: 16rem;

			>p {
				margin: .5rem auto;
				text-align: center;
				font: normal 1rem monospace;
			}

			>div.flex.wrap {
				justify-content: center;
				align-items: center;
				padding: .25rem;
				border-radius: .5rem;

				>a.svg.image.link {
					margin: .25rem .5rem;
					padding: .25rem;
					transition: var(--transition);

					>svg {
						width: 1.5rem;
						height: 1.5rem;
						transition: var(--transition);
						fill: var(--text)
					}

					>svg.instagram.logo {
						fill: var(--purple);
					}

					>svg.github.logo {
						fill: var(--text)
					}

					>svg.linkedin.logo {
						fill: var(--blue)
					}

					>svg:hover {
						fill: white !important;
						filter: drop-shadow(var(--neon-yellow));
					}
				}

				>a.sponsor.button {
					margin: .25rem 1rem;
					padding: .25rem 0rem;
				}
			}
		}
	}

	>div.stats {
		margin-top: 3rem;
		justify-content: center;

		>div.buttons.column {
			margin: 1rem 2rem;
			justify-content: center;
		}
	}

	>div.showcase {
		padding: 0rem 1rem;
		justify-content: center;
		align-self: center;
		max-width: 56rem;

		>a {
			flex: 1 1 25rem;
			margin: 1rem;
			padding: 1rem;
			border-radius: .5rem;
			border: 2px solid var(--main);
			background: var(--card);
			align-items: flex-start;
			justify-content: space-between;
			transition: var(--transition);
			text-overflow: ellipsis;

			&:hover {
				--neon-mint: 0 0 .3rem var(--mint0);
				border: 2px solid var(--mariana3);
				box-shadow:
					var(--neon-mint),
					inset var(--neon-mint);
			}

			* {
				max-width: calc(100vw - 6rem);
			}

			>div.title {

				>h3 {
					margin: 0rem;
					color: var(--mint);
					font-weight: normal;
					overflow-x: hidden;
					text-overflow: ellipsis;
				}

				>div.description {
					margin: .25rem 0rem 0rem 0rem;
					font-size: .8rem;
					opacity: 75%;
				}
			}

			>div.badges {
				margin: 1rem 0rem 0rem 0rem;

				>img {
					margin: 0rem .25rem;
				}
			}

			>div.engagement {
				margin: 1rem 0rem 0rem 0rem;

				>div {
					margin: 0rem .5rem;
					padding: .25rem .5rem;
					border-radius: .5rem;
					vertical-align: middle;
					background: var(--main);
				}
			}
		}
	}
}
