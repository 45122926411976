div.github.stats {
	margin: 1rem 2rem;

	>div.table {
		border-radius: .5rem;
		border: 2px solid var(--mariana0);

		>div {
			display: flex;
			height: 2rem;
			padding: auto .5rem;
			justify-content: space-evenly;
			align-items: center;
			text-align: center;
			background: var(--mariana0);
			color: var(--mariana3);
		}

		>table {
			width: 100%;
			height: calc(100% - 1.9rem);
			font-size: .8rem;

			tr:first-child {
				border: none;
			}

			tr {
				border-top: 2px solid var(--mariana0);
				line-height: 1rem;

				>td {
					padding: .375rem .5rem;
				}

				>td.value {
					font: normal 1rem monospace;
					text-align: right;
				}
			}
		}
	}
}
