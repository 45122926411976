div.button.dimensional {
	min-width: 2rem;
	min-height: 1.5rem;
	border-radius: .5rem;
	margin-top: .25rem;
	translate: 0% 12.5%;
	background: var(--red0);
	position: relative;
	cursor: pointer;
	user-select: none;

	>div {
		position: absolute;
		bottom: min(1rem, 25%);
		width: 100%;
		height: 100%;
		padding: .5rem;
		border-radius: inherit;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		color: var(--mariana0);
		background: var(--red3);
		font-family: monospace;

		&:hover {
			bottom: min(.8rem, 20%);
			filter: brightness(1.1);
		}

		&:active {
			bottom: min(.8rem, 10%);
			filter: brightness(.9);
		}
	}
}

div.button.dimensional.dark {
	background: black;

	>div {
		color: var(--mariana3);
		background: var(--mariana1);
	}
}

div.button.dimensional.light {
	background: hsla(219, 10%, 50%, 100%);

	>div {
		background: var(--mariana3);
	}
}

div.button.dimensional.red {
	background: var(--red0);

	>div {
		background: var(--red3);
	}
}

div.button.dimensional.orange {
	background: var(--orange0);

	>div {
		background: var(--orange3);
	}
}

div.button.dimensional.mint {
	background: var(--mint0);

	>div {
		background: var(--mint3);
	}
}

div.button.dimensional.teal {
	background: var(--teal0);

	>div {
		background: var(--teal3);
	}
}

div.button.dimensional.blue {
	background: var(--blue0);

	>div {
		background: var(--blue3);
	}
}
