div.vscode.stats {
	margin: 1rem 2rem;

	>h3 {
		margin: 0rem;
		font: normal 1.25rem sans-serif;
		text-align: center;
	}

	>div.table {
		margin-top: .5rem;
		border-radius: .5rem;
		font-size: .8rem;
		border: 2px solid var(--blue);

		>div.total {
			height: 2rem;
			padding: auto .5rem;
			justify-content: space-evenly;
			align-items: center;
			background: var(--blue);
			color: var(--mariana0);

			>div:last-child {
				font: normal 1.5rem monospace;
			}
		}

		>table {
			width: 100%;

			tr:first-child {
				border: none;
			}

			tr {
				border-top: 2px solid var(--blue);
				line-height: 1rem;

				>td {
					padding: .25rem .5rem;
				}

				>td.name {
					text-align: center;
				}

				>td.installs {
					font: normal 1rem monospace;
					text-align: right;
				}
			}
		}
	}
}
