div.flag {
	width: 4.5rem;
	height: 3rem;
}

div.german.flag {
	display: flex;
	flex-direction: column;

	>div {
		width: 100%;
		height: 33.3%;
	}

	>div:nth-child(1) {
		border-radius: .5rem .5rem 0rem 0rem;
		background: var(--mariana0);
	}

	>div:nth-child(2) {
		background: var(--red3);
	}

	>div:nth-child(3) {
		border-radius: 0rem 0rem .5rem .5rem;
		background: var(--orange3);
	}
}

div.french.flag {
	display: flex;

	>div {
		width: 33.3%;
		height: 100%;
	}

	>div:nth-child(1) {
		border-radius: .5rem 0rem 0rem .5rem;
		background: var(--blue3);
	}

	>div:nth-child(2) {
		background: white;
	}

	>div:nth-child(3) {
		border-radius: 0rem .5rem .5rem 0rem;
		background: var(--red3);
	}
}

div.italian.flag {
	display: flex;

	>div {
		width: 33.3%;
		height: 100%;
	}

	>div:nth-child(1) {
		border-radius: .5rem 0rem 0rem .5rem;
		background: var(--mint3);
	}

	>div:nth-child(2) {
		background: white;
	}

	>div:nth-child(3) {
		border-radius: 0rem .5rem .5rem 0rem;
		background: var(--red3);
	}
}

div.irish.flag {
	display: flex;

	>div {
		width: 33.3%;
		height: 100%;
	}

	>div:nth-child(1) {
		border-radius: .5rem 0rem 0rem .5rem;
		background: var(--mint3);
	}

	>div:nth-child(2) {
		background: white;
	}

	>div:nth-child(3) {
		border-radius: 0rem .5rem .5rem 0rem;
		background: var(--orange3);
	}
}

div.us.flag {
	display: flex;
	flex-direction: column;

	>div:first-child {
		width: 100%;
		height: 53.8%;
		display: flex;

		>div:first-child {
			width: 40%;
			height: 100%;

			// border-radius: .5rem 0rem 0rem 0rem;
			background: var(--blue3);
		}

		>div:last-child {
			width: 60%;
			height: 100%;
			display: flex;
			flex-direction: column;

			>div {
				width: 100%;
				height: 14.3%;
			}

			// >div:first-child {
			// 	border-radius: 0rem .5rem 0rem 0rem;
			// }

			>div:nth-child(odd) {
				background: var(--red3);
			}

			>div:nth-child(even) {
				background: white;
			}
		}
	}

	>div:last-child {
		width: 100%;
		height: 46.2%;
		display: flex;
		flex-direction: column;

		>div {
			width: 100%;
			height: 16.6%;
		}

		>div:nth-child(odd) {
			background: white;
		}

		>div:nth-child(even) {
			background: var(--red3);
		}

		// >div:last-child {
		// 	border-radius: 0% 0% .5rem .5rem;
		// }
	}
}
